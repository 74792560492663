
    .MarketingSucceed{
        .success-content {
            margin-top: 155px;
            text-align: center;
            .iconfont {
                color: #1FD18C;
                font-size: 52px;
            }
            .text {
                font-size: 24px;
                margin: 20px 0 0;
                font-weight: bold;
            }
        }
        .btn{
            margin-top: 60px;
            text-align: center;
        }
    }
